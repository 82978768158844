import React from "react"
import "./layout.css"
import MainNavbar from "./navbar"
import Footer from "./footer"

export default function Layout({ children }) {
    return (
        <>
            <MainNavbar />

            {children}

            <Footer />
        </>
    )
}