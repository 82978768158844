import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faSoundcloud, faSpotify, faYoutube } from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
    return (
        <footer>
            <a href="https://www.instagram.com/_nutfield_/">
                <FontAwesomeIcon icon={faInstagram} />
                <span className="sr-only">Instagram</span>
            </a>
            <a href="https://www.youtube.com/channel/UCwa00H8uwTMyWPcbsjXdB9w">
                <FontAwesomeIcon icon={faYoutube} />
                <span className="sr-only">YouTube</span>
            </a>
            <a href="https://open.spotify.com/artist/6nY7irLhT80qUxAw4kKUf5?si=qFXMnei8Qwq0iLE47KIaEw">
                <FontAwesomeIcon icon={faSpotify} />
                <span className="sr-only">Spotify</span>
            </a>
            <a href="https://soundcloud.com/n_u_t_f_i_e_l_d">
                <FontAwesomeIcon icon={faSoundcloud} />
                <span className="sr-only">SoundCloud</span>
            </a>
        </footer>
    )
};

export default Footer;