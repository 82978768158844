import React from "react"
import { Nav, Navbar } from "react-bootstrap"

const MainNavbar = () => {
    return (
        <Navbar bg="dark" variant="dark" collapseOnSelect expand="lg">
            <Navbar.Brand href="/">NUTFIELD</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="/videos/">Videos</Nav.Link>
                    <Nav.Link href="/music/">Music</Nav.Link>
                    <Nav.Link href="/about/">About</Nav.Link>
                    <Nav.Link href="#contact">contact</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
};

export default MainNavbar;